import isString from 'src/utils/isString';

type KnownErrors = {[key: string]: string | React.ReactNode};

const getErrorString = (error: any, knownErrors: KnownErrors) => {
  if (!error) return '';

  // Error is failed response
  if (error.res && error.data) {
    if (knownErrors && knownErrors[error.data.message]) return knownErrors[error.data.message];
  }

  if (isString(error)) {
    if (knownErrors && knownErrors[error]) return knownErrors[error];

    return error;
  }

  return 'Something went wrong.';
};

const getErrors = (errors: any, knownErrors: KnownErrors) => {
  if (Array.isArray(errors)) {
    if (!errors.length) return null;

    return errors.map((error) => getErrorString(error, knownErrors));
  }

  if (!errors) return null;

  return [getErrorString(errors, knownErrors)];
};

type Props = {
  errors: any;
  knownErrors?: {[key: string]: string | React.ReactNode};
  className?: string;
};

const FormErrors = ({errors, knownErrors, className}: Props) => {
  const errorStrings = getErrors(errors, knownErrors);

  if (!errorStrings) return null;

  return (
    <div className={className}>
      {errorStrings && (
        <div className="bg-[rgba(228,16,54,0.05)] py-3 px-4 rounded-xl">
          {errorStrings.map((errorString, i) => (
            <span key={i} className="text-lg text-[--error-color]">
              {errorString}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default FormErrors;
