import {FormEvent} from 'react';
import * as Form from '@radix-ui/react-form';
import {useMutation} from '@tanstack/react-query';
import TextInput, {getValuesFromFormEvent} from './form/TextInput';
import recordEvent from 'src/utils/recordEvent';
import {signupErrors} from 'src/utils/errors';
import fetcher from 'src/utils/fetcher';
import FormErrors from './FormErrors';
import BaseButton from './BaseButton';
import LogoIcon from './LogoIcon';

type EditableValues = {
  name: string;
  email: string;
};

type Props = {
  setAuthSession: Function;
  goToLogin: () => void;
};

const SignUpForm = ({setAuthSession, goToLogin}: Props) => {
  const {
    mutateAsync: signUp,
    error,
    isPending
  } = useMutation({
    mutationFn: (values: EditableValues) =>
      fetcher({
        url: '/api/auth/signup',
        options: {method: 'POST'},
        body: values
      }),
    onSuccess: (data) => {
      recordEvent('signedUp');
      setAuthSession(data);
    }
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    const values = getValuesFromFormEvent(event, ['name', 'email']) as EditableValues;

    event.preventDefault();

    signUp(values);
  };

  return (
    <Form.Root className="flex flex-col pt-3" onSubmit={onSubmit}>
      <div className="flex flex-col items-center">
        <LogoIcon width={40} height={46} />

        <h2 className="text-4xl text-center text-[--purple-text] mt-4">Join Humbo</h2>

        <p className="text-lg/snug text-center [text-wrap:balance] mt-2 mb-9">
          Welcome! We only need your name and email.
        </p>
      </div>

      <TextInput
        name="name"
        label="Name"
        required
        autoFocus
        autoComplete={false}
        minLength={3}
        maxLength={50}
        validations={[
          {match: 'valueMissing', children: 'What is your name?'},
          {match: 'tooShort', children: 'Name should be at least 3 letters long.'},
          {match: 'tooLong', children: 'Name should be at most 50 letters long.'}
        ]}
      />

      <TextInput
        name="email"
        label="Email"
        required
        type="email"
        validations={[{match: 'valueMissing', children: 'What is your email?'}]}
      />

      <div className="[&_a]:text-[--purple-text]">
        <p className="text-[--gray-text]">
          By creating an account, you agree to the{' '}
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>{' '}
          &{' '}
          <a href="/terms" target="_blank">
            Terms of Service
          </a>
        </p>

        <div className="mt-3 mb-6">
          <span className="text-[--gray-text]">Already have an account?</span>
          <BaseButton
            className="inline h-auto py-1 px-2 ml-1"
            color="var(--purple-text)"
            buttonVariant="text"
            onClick={goToLogin}
            label="Log in"
            type="button"
          />
        </div>
      </div>

      <FormErrors errors={error} knownErrors={signupErrors} className="mb-6" />

      <Form.Submit asChild>
        <BaseButton
          type="submit"
          label="Continue"
          buttonVariant="primary"
          className="flex-grow"
          isLoading={isPending}
        />
      </Form.Submit>
    </Form.Root>
  );
};

export default SignUpForm;
