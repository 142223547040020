'use client';

import {twMerge} from 'tailwind-merge';
import isBoolean from 'lodash/isBoolean';
import {useEffect, useState} from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import {AnimatePresence, LayoutGroup, LazyMotion, m as motion} from 'framer-motion';
import {closeLine} from 'src/utils/icons';
import BaseButton from './BaseButton';

const allAnimations = () => import('src/utils/framerFeatures').then((res) => res.domMax);

export const modalTransition = {type: 'spring', duration: 0.4, bounce: 0.2};

export type ResponsiveModalProps = {
  onClose?: () => void;
  children?: any;
  trigger?: React.ReactNode;
  title?: string;
  className?: string;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  defaultOpen?: boolean;
  modal?: boolean;
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
  onInteractOutside?: (event: Event) => void;
  isVisuallyHidden?: boolean;
};

const ResponsiveModal = ({
  onClose,
  children,
  trigger,
  title,
  className,
  isOpen,
  setIsOpen,
  defaultOpen,
  modal,
  onEscapeKeyDown,
  onInteractOutside,
  isVisuallyHidden
}: ResponsiveModalProps) => {
  const [localOpen, setLocalOpen] = useState(Boolean(isOpen) || defaultOpen);

  const open = isBoolean(isOpen) ? isOpen : localOpen;
  const setOpen = setIsOpen ? setIsOpen : setLocalOpen;

  const onOpenChange = (open: boolean) => {
    setOpen(open);

    if (!open && onClose) onClose();
  };

  useEffect(() => {
    if (!open) {
      document.documentElement.style.setProperty('--vh', '100%');
      return;
    }

    const updateHeight = () => {
      const vh = window.visualViewport?.height;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateHeight();

    window.visualViewport?.addEventListener('resize', updateHeight);

    return () => {
      document.documentElement.style.setProperty('--vh', '100%');
      window.visualViewport?.removeEventListener('resize', updateHeight);
    };
  }, [open]);

  return (
    <LazyMotion features={allAnimations} strict>
      <Dialog.Root open={open} onOpenChange={onOpenChange} defaultOpen={defaultOpen} modal={modal}>
        <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>

        <AnimatePresence>
          {open ? (
            <Dialog.Portal forceMount>
              <motion.div className="fixed top-0 h-[--vh] transition-[height] duration-300 right-0 left-0 z-20 flex flex-col items-center justify-center">
                <LayoutGroup>
                  <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(151,149,153,0.2)] dark:bg-[rgba(0,0,0,0.3)] z-20"
                  />

                  <Dialog.Content
                    key="content"
                    asChild
                    onEscapeKeyDown={onEscapeKeyDown}
                    onInteractOutside={onInteractOutside}
                  >
                    <motion.div
                      layout
                      className={twMerge(
                        'relative z-[21] focus:[&_input]:animate-[appear_0.001s] focus:[&_textarea]:animate-[appear_0.001s] bg-[rgb(255_255_255_/_0.85)] dark:bg-[rgb(22_15_25_/_0.85)] backdrop-blur-lg backdrop-saturate-150 w-[420px] max-w-[calc(100%-20px)] min-h-[100px] max-h-[calc(100%-20px)] shadow-[inset_0_0_1px_1px_rgb(255_255_255_/_0.5),0_0_0_1px_rgb(23_10_37_/_0.1),0_3px_20px_rgb(23_10_37_/_0.06),0_20px_80px_rgb(23_10_37_/_0.1)] dark:shadow-[inset_0_0_1px_1px_rgb(255_255_255_/_0.1),0_0_0_1px_rgb(23_10_37_/_0.1),0_3px_20px_rgb(23_10_37_/_0.06),0_20px_80px_rgb(23_10_37_/_0.1)] p-6 overflow-y-auto',
                        className
                      )}
                      initial={{opacity: 0, scale: 0.9}}
                      animate={{opacity: isVisuallyHidden ? 0 : 1, scale: 1}}
                      exit={{opacity: 0, scale: 0.95}}
                      transition={modalTransition}
                      style={{borderRadius: 24}}
                      layoutScroll
                    >
                      {title ? (
                        <motion.h2 layout className="text-lg leading-tight pr-4 -mt-1" transition={modalTransition}>
                          {title}
                        </motion.h2>
                      ) : null}

                      <motion.div layout className="absolute right-4 top-4" transition={modalTransition}>
                        <Dialog.Close asChild>
                          <BaseButton icon={closeLine} buttonVariant="secondary" className="w-[32px] h-[32px]" />
                        </Dialog.Close>
                      </motion.div>

                      {children}
                    </motion.div>
                  </Dialog.Content>
                </LayoutGroup>
              </motion.div>
            </Dialog.Portal>
          ) : null}
        </AnimatePresence>
      </Dialog.Root>
    </LazyMotion>
  );
};

export default ResponsiveModal;
