import {useSyncExternalStore} from 'react';

const getBreakpointFromWidth = (width: number, breakpoints: number[]): number => {
  const activeIndex = breakpoints.findIndex((breakpoint) => width < breakpoint);
  const adjustedIndex = activeIndex === -1 ? breakpoints.length : activeIndex;

  return adjustedIndex;
};

const subscribe = (callback: () => void) => {
  window.addEventListener('resize', callback);

  return () => {
    window.removeEventListener('resize', callback);
  };
};

const useWidthBreakpoints = (breakpoints: number[], defaultBreakpoint: number = 0): number => {
  return useSyncExternalStore(
    subscribe,
    () => getBreakpointFromWidth(window.innerWidth, breakpoints),
    () => defaultBreakpoint
  );
};

export default useWidthBreakpoints;
