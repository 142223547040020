'use client';

import {useMemo, useState} from 'react';
import {addCircleLine, bucket, earthLine, menu4Line, roadMapFill, trophyLine, userSmileLine} from 'src/utils/icons';
import {getSignedInContextMenuItems, getMobileContextMenuItems} from 'src/utils/getNavMenuItems';
import SkipRenderOnClient from '../common/SkipRenderOnClient';
import useLogout from 'src/utils/useLogout';
import AuthModal from '../auth/AuthModal';
import ThemeToggle from './ThemeToggle';
import BaseButton from '../BaseButton';
import RemixIcon from '../RemixIcon';
import Dropdown from '../Dropdown';

const navButtonClassName = 'flex-col text-sm h-14 px-4 max-[700px]:px-2 rounded-lg font-bold';

type Props = {
  user: User | null;
};

const NavButtons = ({user}: Props) => {
  const logout = useLogout();

  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const userContextMenuItems = useMemo(
    () => [
      {component: <ThemeToggle key="theme-toggle" />},
      'separator' as const,
      ...getSignedInContextMenuItems(Boolean(user?.isAdmin), logout)
    ],
    [logout]
  );

  const mobileContextMenuItems = useMemo(
    () =>
      getMobileContextMenuItems(
        user,
        logout,
        () => setIsJoinModalOpen(true),
        () => setIsLoginModalOpen(true)
      ),
    [user, setIsJoinModalOpen, setIsLoginModalOpen]
  );

  return (
    <>
      <SkipRenderOnClient minWidth={1050} className="flex justify-center max-[1050px]:hidden">
        {user ? (
          <>
            <BaseButton
              prefetch={false}
              color="var(--dynamic-purple)"
              className={navButtonClassName}
              buttonVariant="text"
              href={`/${user?.nameId}/lists`}
            >
              <RemixIcon icon={bucket} color="var(--dynamic-purple)" />
              Lists
            </BaseButton>

            <BaseButton
              prefetch={false}
              color="var(--dynamic-purple)"
              className={navButtonClassName}
              buttonVariant="text"
              href={`/${user?.nameId}/map`}
            >
              <RemixIcon icon={earthLine} color="var(--dynamic-purple)" />

              {user.usedVisitedFeature ? null : (
                <span className="bg-[#DF5531] z-[1] text-white leading-none py-[3px] px-1 text-[12px] rounded-md -my-2 shadow-[inset_0px_1px_1px_rgba(255,255,255,0.2),0px_1px_3px_rgba(101,39,131,0.3),0px_5px_11px_rgba(101,39,131,0.3)]">
                  New
                </span>
              )}

              <span>Visited</span>
            </BaseButton>

            <Dropdown items={userContextMenuItems}>
              <BaseButton color="var(--dynamic-purple)" className={navButtonClassName} buttonVariant="text">
                <RemixIcon icon={userSmileLine} color="var(--dynamic-purple)" />
                <div className="max-w-[80px] relative">
                  <span className="block truncate">{user.name}</span>
                </div>
              </BaseButton>
            </Dropdown>
          </>
        ) : (
          <>
            <BaseButton
              href="/top100"
              color="var(--dynamic-purple)"
              className={navButtonClassName}
              buttonVariant="text"
            >
              <RemixIcon icon={trophyLine} color="var(--dynamic-purple)" size={20} className="m-[2px]" />
              Top 100
            </BaseButton>

            <BaseButton
              href="/visited"
              color="var(--dynamic-purple)"
              className={navButtonClassName}
              buttonVariant="text"
            >
              <RemixIcon icon={roadMapFill} color="var(--dynamic-purple)" size={20} className="m-[2px]" />
              Visited
            </BaseButton>

            <BaseButton
              onClick={() => setIsJoinModalOpen(true)}
              color="var(--dynamic-purple)"
              className={navButtonClassName}
              buttonVariant="text"
            >
              <RemixIcon icon={addCircleLine} color="var(--dynamic-purple)" size={20} className="m-[2px]" />
              Join
            </BaseButton>

            <Dropdown items={[{component: <ThemeToggle key="theme-toggle" className="mb-0" />}]}>
              <BaseButton color="var(--dynamic-purple)" className={navButtonClassName} buttonVariant="text">
                <RemixIcon icon={menu4Line} color="var(--dynamic-purple)" />
                Menu
              </BaseButton>
            </Dropdown>
          </>
        )}
      </SkipRenderOnClient>

      <SkipRenderOnClient maxWidth={1050} className="justify-center hidden max-[1050px]:flex">
        <Dropdown items={mobileContextMenuItems}>
          <BaseButton color="var(--dynamic-purple)" className={navButtonClassName} buttonVariant="text">
            <RemixIcon icon={menu4Line} color="var(--dynamic-purple)" />
            Menu
          </BaseButton>
        </Dropdown>
      </SkipRenderOnClient>

      {user ? null : (
        <>
          <AuthModal initialView="join" isOpen={isJoinModalOpen} setIsOpen={setIsJoinModalOpen} />
          <AuthModal initialView="login" isOpen={isLoginModalOpen} setIsOpen={setIsLoginModalOpen} />
        </>
      )}
    </>
  );
};

export default NavButtons;
