import {DropdownMenuItem} from 'src/components/Dropdown';
import {
  addCircleLine,
  articleLine,
  bucket,
  earthLine,
  gameLine,
  loginCircleLine,
  logoutCircleLine,
  roadMapFill,
  settingsLine,
  trophyLine
} from './icons';
import ThemeToggle from 'src/components/navigation/ThemeToggle';

const getSignedInNavMenuItems = (userNameId: string | undefined) => [
  {
    icon: bucket,
    text: 'My Lists',
    key: 'my-lists',
    href: `/${userNameId}/lists`
  },
  {
    icon: earthLine,
    text: 'Visited',
    key: 'visited',
    href: `/${userNameId}/map`
  }
];

export const getSignedInContextMenuItems = (isAdmin: boolean, logout: () => void): DropdownMenuItem[] =>
  [
    isAdmin && {
      icon: gameLine,
      text: 'Admin',
      href: '/admin/places',
      key: 'admin'
    },
    {
      icon: settingsLine,
      text: 'Account',
      href: '/settings',
      key: 'account'
    },
    {
      icon: logoutCircleLine,
      text: 'Log Out',
      onAction: logout,
      key: 'logout'
    }
  ].filter(Boolean);

const getSignedOutContexMenuItems = (openJoinModal: () => void, openLoginModal: () => void) => [
  {component: <ThemeToggle key="toggle" />, key: 'toggle'},
  'separator' as const,
  {
    icon: trophyLine,
    href: '/top100',
    text: 'Top 100',
    key: 'top100'
  },
  {
    icon: roadMapFill,
    href: '/visited',
    text: 'Visited',
    key: 'visited'
  },
  {
    icon: articleLine,
    href: '/lists',
    text: 'Articles',
    key: 'articles'
  },
  {
    icon: addCircleLine,
    onClick: openJoinModal,
    text: 'Join Humbo',
    key: 'join'
  },
  {
    icon: loginCircleLine,
    onClick: openLoginModal,
    text: 'Log In',
    key: 'login'
  }
];

export const getMobileContextMenuItems = (
  session: IronSession | User | null,
  logOut: () => void,
  openJoinModal: () => void,
  openLoginModal: () => void
) => {
  if (!session) return getSignedOutContexMenuItems(openJoinModal, openLoginModal);

  return [
    {component: <ThemeToggle key="toggle" />, key: 'toggle'},
    'separator' as const,
    ...getSignedInNavMenuItems(session?.nameId),
    ...getSignedInContextMenuItems(Boolean(session?.isAdmin), logOut)
  ];
};
