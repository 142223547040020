'use client';

import {get} from 'lodash';
import {FormikProps} from 'formik';
import {twMerge} from 'tailwind-merge';
import * as Form from '@radix-ui/react-form';
import TextareaAutosize from 'react-textarea-autosize';
import {ChangeEvent, FormEvent, HTMLInputTypeAttribute} from 'react';

export const getValuesFromFormEvent = (form: FormEvent<HTMLFormElement>, keys: string[]) => {
  const values: {[key: string]: string} = {};

  keys.forEach((key) => {
    values[key] = (form.currentTarget[key] as HTMLInputElement).value;
  });

  return values;
};

export const textInputClassName =
  'px-3 py-2 rounded-xl bg-[--button-bg-active] shadow-[inset_0_0_0_1px_rgb(23_10_37_/_0.05)] dark:shadow-[inset_0_0_0_1px_rgb(255_255_255_/_0.05)] placeholder:text-[--gray-text] text-lg data-[invalid]:shadow-[inset_0_0_0_1px_#e41036] data-[invalid]:bg-[rgba(228,16,54,0.05)] data-[invalid]:placeholder:text-[rgba(228,16,54,0.5)] focus:shadow-[inset_0_0_0_1px_rgb(23_10_37_/_0.15)] dark:focus:shadow-[inset_0_0_0_1px_rgb(255_255_255_/_0.15)] disabled:text-[--gray-text] disabled:cursor-no-drop';

export type TextInputProps = {
  name: string;
  label: string;
  validations?: Form.FormMessageProps[];
  autoFocus?: boolean;
  required?: boolean;
  textarea?: boolean;
  autoSize?: boolean;
  minRows?: number;
  defaultValue?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  autoComplete?: boolean;
  minLength?: number;
  maxLength?: number;
  type?: HTMLInputTypeAttribute;
  LabelComponent?: React.ReactNode;
  AfterInputComponent?: React.ReactNode;
  className?: string;
  inputClassName?: string;
  inputWrapperClassName?: string;
  disabled?: boolean;
};

const TextInput = ({
  name,
  label,
  validations = [],
  autoFocus,
  required,
  textarea,
  minRows = 3,
  defaultValue,
  value,
  onChange,
  autoComplete = true,
  minLength,
  maxLength,
  type,
  LabelComponent,
  AfterInputComponent,
  inputClassName,
  inputWrapperClassName,
  className,
  autoSize = true,
  disabled
}: TextInputProps) => {
  return (
    <Form.Field name={name} className={twMerge('flex flex-col mb-4', className)}>
      {LabelComponent}

      <div className={twMerge('relative flex flex-col', inputWrapperClassName)}>
        <Form.Control
          disabled={disabled}
          placeholder={label}
          className={twMerge(textInputClassName, inputClassName)}
          required={required}
          autoFocus={autoFocus}
          asChild={textarea}
          minLength={minLength}
          type={type}
          maxLength={maxLength}
          autoComplete={autoComplete ? undefined : 'off'}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
        >
          {textarea ? autoSize ? <TextareaAutosize minRows={minRows} /> : <textarea /> : null}
        </Form.Control>

        {AfterInputComponent}
      </div>

      {validations.map((validation, index) => (
        <Form.Message key={index} {...validation} className="text-sm ml-3 mt-2 text-[--error-color]" />
      ))}
    </Form.Field>
  );
};

export type FormikTextInputProps = TextInputProps & {formik: FormikProps<any>};

export const FormikTextInput = (props: FormikTextInputProps) => {
  const value = get(props.formik.values, props.name) || '';

  return (
    <TextInput {...props} value={value} onChange={(e) => props.formik.setFieldValue(props.name, e.target.value)} />
  );
};

export default TextInput;
