import {memo} from 'react';

type Props = {
  height?: number;
  width?: number;
  className?: string;
};

const LogoIcon = ({width = 40, height = 46, className}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 46"
      className={className}
      fill="var(--dynamic-purple)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4347 19.194C28.5959 10.5305 22.9192 3.89448 16.4511 3.68009C16.0298 3.6661 15.6058 3.67822 15.179 3.72017C12.8277 3.95041 10.7094 5.01494 8.96691 6.67791C7.9444 7.56253 7.10654 8.62146 6.51085 9.83048C6.28631 10.2882 6.09424 10.7673 5.94207 11.2669C4.08356 17.3539 8.57071 24.1354 15.9658 26.4145C20.6524 27.8594 25.2667 27.6105 29.2129 26.7287C29.5757 24.3442 29.6889 21.8199 29.4347 19.194Z"
        fill="transparent"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6267 19.3236C38.1811 16.611 35.416 12.255 31.1274 8.76875C32.4097 11.0153 33.255 13.5908 33.525 16.3733C33.8424 19.6564 33.5065 22.8043 32.7596 25.7452C35.0634 24.9781 37.0008 24.0739 38.4019 23.3347C39.9041 22.5414 40.4274 20.8262 39.6267 19.3236Z"
        fill="#DF5531"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1274 8.76855C28.8384 6.90796 26.116 5.2944 22.927 4.3119C20.8087 3.65939 18.682 3.44779 16.6815 3.6193C23.1487 3.83463 28.8263 10.4707 29.6651 19.1342C29.9194 21.7601 29.8062 24.2844 29.4434 26.6679C30.6125 26.4069 31.7231 26.0899 32.7595 25.745C33.5065 22.8041 33.8424 19.6571 33.525 16.3731C33.255 13.5915 32.4097 11.0151 31.1274 8.76855Z"
        fill="#EAB22D"
      />

      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
        <path d="M0 0H31.1269V45.7615H0V0Z" fill="white" />
      </mask>

      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1953 26.3549C8.8012 24.0757 4.31312 17.2933 6.17164 11.2073C6.3238 10.7076 6.51587 10.2285 6.74134 9.77081C7.3361 8.5618 8.17397 7.50194 9.19647 6.61732C10.939 4.95435 13.0573 3.88982 15.4085 3.66051C15.8353 3.61856 16.2594 3.60551 16.6806 3.61949C18.682 3.44798 20.8087 3.65958 22.927 4.31209C26.1161 5.29458 28.8384 6.90815 31.1265 8.76874C27.8456 3.01732 21.705 -0.57336 15.0615 0.075423C5.82369 0.977753 -0.88848 9.73726 0.070003 19.6414C1.34953 32.8557 12.5906 41.8651 17.3951 45.1453C17.4517 45.1836 17.5073 45.2208 17.5612 45.2581C18.0316 45.576 18.5642 45.741 19.0977 45.7596C19.1088 45.7606 19.12 45.7606 19.1311 45.7606C19.1599 45.7615 19.1896 45.7615 19.2193 45.7615C19.2415 45.7615 19.2638 45.7606 19.287 45.7596C19.3074 45.7587 19.3278 45.7587 19.3482 45.7578C19.3928 45.7559 19.4373 45.7522 19.4818 45.7475C19.5264 45.7438 19.5709 45.7382 19.6155 45.7317C19.6359 45.7289 19.6563 45.7251 19.6758 45.7214C19.699 45.7177 19.7212 45.714 19.7435 45.7102C19.7723 45.7046 19.801 45.699 19.8298 45.6925C19.84 45.6897 19.8511 45.6869 19.8623 45.6851C20.3819 45.5639 20.8727 45.2982 21.2735 44.8955C21.3199 44.8489 21.3673 44.8004 21.4146 44.7529C21.4526 44.7091 21.4888 44.6634 21.5241 44.6177C24.021 41.3393 28.201 34.8273 29.4425 26.6681C25.4972 27.5499 20.8829 27.7988 16.1953 26.3549Z"
          fill="inherit"
        />
      </g>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5038 13.1746C21.4166 14.3323 20.7931 18.0973 17.3053 18.3015C14.7286 18.4525 12.4516 16.6637 12.202 14.0835C12.048 12.4941 13.8323 9.20733 16.4006 8.95658C18.5328 8.74777 21.7553 9.83374 21.5038 13.1746Z"
        fill="inherit"
      />
    </svg>
  );
};

export default memo(LogoIcon);
