'use client';

import {useRouter} from 'next/navigation';
import {useMutation} from '@tanstack/react-query';
import fetcher from './fetcher';

const useLogout = () => {
  const router = useRouter();

  const {mutateAsync: logoutFunction} = useMutation({mutationFn: fetcher});

  const logout = async () => {
    await logoutFunction({
      url: '/api/auth/logout',
      options: {method: 'POST'}
    });

    router.push('/');
    router.refresh();
  };

  return logout;
};

export default useLogout;
